class AweLoader extends HTMLElement {

  get active() {
    return this._active
  }
  set active(value) {
    if (!!value !== this._active) {
      this._active = !!value
      if (this._active)
        this.setAttribute('active', '')
      else
        this.removeAttribute('active')
    }

    return this._active // eslint-disable-line no-setter-return
  }

  constructor() {
    super()

    this._active = false; // whether loader is active
    this._counter = 0 // number of active show requests
    this._timeoutId = 0 // id of the timeout used to delay the show
    this._transitionAdded = false
    this._startTs = 0

    this._DEFAULT_DELAY = 500
    this._MIN_VIZ_DURATION = 500

    if (this.getAttribute('delay'))
      this.delay = parseInt(this.getAttribute('delay'), 10) || this._DEFAULT_DELAY
    else
      this.delay = this._DEFAULT_DELAY

    this.attachShadow({ mode: 'open' })

    const iconSlot = document.createElement('slot')

    iconSlot.name = 'icon'
    iconSlot.innerHTML = `
    <svg class="spinner" viewBox="0 0 50 50">
    <circle class="path" cx="25" cy="25" r="20" fill="none" stroke-width="2"></circle>
    </svg>`

    const style = document.createElement('style')

    style.textContent = `
    :host{
      display: block;
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      opacity: 0;
      transform: scale(0);
    }
    :host([active]){
      transform: none;
      opacity: 1;
      z-index: 10;
    }

    .spinner {
      animation: rotate 2s linear infinite;
      z-index: 2;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 50%;
      height: 50%; 
    }
    .spinner .path {
      opacity: .8;
      stroke: var(--loadbar-color, #93BFEC);
      stroke-linecap: round;
      animation: dash 1.5s ease-in-out infinite;
    }
    
    @keyframes rotate {
      100% {
        transform: translate(-50%, -50%) rotate(360deg);
      }
    }
    
    @keyframes dash {
      0% {
        stroke-dasharray: 1, 150;
        stroke-dashoffset: 0;
      }
      50% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -35;
      }
      100% {
        stroke-dasharray: 90, 150;
        stroke-dashoffset: -124;
      }
    }
    `
    this.shadowRoot.append(style, iconSlot)

    // event bindings
    document.addEventListener('loader-start', e => {
      // ignore event if it has a target and it's not this instance.
      if (e.detail && e.detail.target && e.detail.target !== this.id)
        return

      this._counter++

      if (this._timeoutId)
        return

      // if transition rule is added in constructor, it applies immediately to reach the defined values.
      // so we add them when first needed.
      if (!this._transitionAdded) {
        this._transitionAdded = true
        const style2 = document.createElement('style')

        style2.textContent += ':host{transition: opacity .4s, transform .3s .1s;}'
        this.shadowRoot.append(style2)
      }

      this._timeoutId = setTimeout(() => {
        this._timeoutId = 0
        this.active = true
        this._startTs = Date.now()
      }, this.delay)

    })
    document.addEventListener('loader-end', e => {
      // ignore event if it has a target and it's not this instance.
      if (e.detail && e.detail.target && e.detail.target !== this.id)
        return

      if (--this._counter <= 0) {
        clearTimeout(this._timeoutId)
        this._timeoutId = 0

        // if loader has been shown less than minimum, delay the hiding by the remaining time.
        if (Date.now() - this._startTs >= this._MIN_VIZ_DURATION)
          this.active = false
        else {
          setTimeout(() => {
            this.active = false
          }, this._MIN_VIZ_DURATION - Date.now() + this._startTs)
        }
      }
    })
  }
}
customElements.define('awe-loader', AweLoader)

// // inject element if missing - probably not necessary
// void function(){
//   let nd = document.getElementsByTagName('awe-loader')[0]
//   if (!nd){
//     nd = document.createElement('awe-loader')
//     document.body.appendChild(nd)
//   }
// }()
